// App Urls ----------------

const APP_DENTAL_URL = "https://app.medibhai.com/dentalconsultation";
const APP_MEDICINE_URL = "https://app.medibhai.com/buyMedicines";
const APP_DIAGNOSTICS_URL = "https://app.medibhai.com/diagnostictest";
const APP_TELECONSULT_URL = "https://app.medibhai.com/teleconsultation";
export const APP_ORDER_URL = "https://app.medibhai.com/order-details";
export const APP_REIMBURSEMENT_URL =
  "https://app.medibhai.com/reimbursment-details";
const APP_NUTRIOTION_URL = "https://app.medibhai.com/teleconsultation";
export const APP_HEALTH_RECORDS_URL = "https://app.medibhai.com/health-records";

const EMED_WEB_LINK = "https://insurance.emedshield.com";

export const PINCODE_API = "https://api.postalpincode.in/pincode/";

export const POLICY_DETAILS_API = "/employee/policy-details";

export let mainAPikey = sessionStorage.getItem("mainApiKey");

// ------------------API's------------------------------------
export const INITIMATE_PATIENT_LIST_API =
  "/employee/intimate-claims-patients-list";
export const SAVE_CLAIM_SUBMISSION_API =
  "/employee/save_claim_submission_details";
export const CHECK_CLAIM_EXIST_API = "/employee/claim_submission_check_hod";
export const UPLOAD_KYC_DOCUMENTS_API = "/employee/kyc_add_documents";
export const CLAIM_DOCUMENTS_LIST_API =
  "/employee/claim_submission_kyc_docs_list_api";
export const CLAIM_SUBMISSION_BY_INTIMATION_ID_API =
  "/employee/claim_submission_details_by_intimateID";
export const DELETE_KYC_DOCUMENT_API =
  "/employee/claim_submission_kyc_docs_list_delete";
export const ADD_CLAIM_DOCUMENT_API = "/employee/claim_add_documents";
export const CLAIM_SUBMISSION_DOCUMENTS_LIST_API =
  "/employee/claim_submission_claim_docs_list_api";
export const CHECK_MEMBER_CLAIM_DETAILS_API =
  "/employee/check-member-claim-details";
export const MAIN_CLAIM_SUBMISSION_API = "/employee/claim-submission-api";

// MEMBERS COVERED ---------------------------
export const ADD_EMPLOYEE_API = "/employee/employee-addition-request";
export const EDIT_EMPLOYEE_API = "/employee/employee-correction-request";
export const DELETE_EMPLOYEE_API = "/employee/employee-deletion-request";
export const RELATION_MASTER = "/employee/get-relationship-master";
export const REQUEST_MASTER = "/employee/get-addition-request";
export const HOME_INDEX_API = "https://medibhai.com/api/employee-home-data";

// export const DEFAULT_CAPTCHA_KEY = "6Ld-RhMqAAAAAM8zxJGBpKSWVocreXfaaSwh4pl4" // FOR PRODUCTION

export const DEFAULT_CAPTCHA_KEY = "6Ld-RhMqAAAAAM8zxJGBpKSWVocreXfaaSwh4pl4" // FOR PRODUCTION

// export const DEFAULT_CAPTCHA_KEY = "6LeY0xIqAAAAAAheR6fqf1ghXVHLtAb6BOf5a6p3"; // FOR UAT

export const DOCUMENT_TYPE = [
  {
    id: 1,
    doc_type: "Cancel Cheque",
  },
  {
    id: 2,
    doc_type: "Pan Card",
  },
  {
    id: 3,
    doc_type: "Aadhaar Card",
  },
];

export const CLAIM_DOC = [
  { value: "Surgical", name: "Surgical" },
  { value: "Medical Management", name: "Medical Management" },
  { value: "Maternity", name: "Maternity" },
  { value: "Cataract", name: "Cataract" },
  { value: "Advanced Treatment", name: "Advanced Treatment" },
  { value: "Daycare Procedure", name: "Daycare Procedure" },
  { value: "Others", name: "Others" },
];

export {
  APP_DENTAL_URL,
  APP_MEDICINE_URL,
  APP_DIAGNOSTICS_URL,
  APP_TELECONSULT_URL,
  EMED_WEB_LINK,
  APP_NUTRIOTION_URL,
};
