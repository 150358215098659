import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useContext, useEffect, useState } from "react";
import { pdficon, download_pdf_icon, downloadbannerimg } from "../Assets/index";
import { AppContext, mainAPikey } from "../ContextApi";
import { useForm, Controller } from "react-hook-form";
import { validateNumberInput, validateTextInput } from "../Utils";
import { ADD_EMPLOYEE_API } from "../Constants";
import { toast } from "react-toastify";
import DependentCard from "./DependentCard";

function RequestModal(props) {
  const { data, type, relationData } = props;
  console.log(data)

  return (
    <Modal {...props} backdrop="static" size="xl" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>Dependent Request List</Modal.Title>
        <Button onClick={props.handleClose}>x</Button>
      </Modal.Header>
      <Modal.Body>
        <DependentCard data={data}/>
      </Modal.Body>
    </Modal>
  );
}

export default RequestModal;
