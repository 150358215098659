import { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import CashlessModal from "../components/CashlessModal";
import { AppContext } from "../ContextApi";
import ErrorDataComponent from "../components/ErrorData";
import HeaderTabs from "../components/HeaderTabs";
import CardLoader from "../components/CardLoader";
import NoDataFound from "../components/NoDataFound";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NotokenFound, handleTokenInResponse } from "../Utils";
import ClaimsFeatures from "../components/ClaimsFeatures";
import Wellness_Benefits from "./Wellness_Benefits";
import HeroSection from "../components/HeroSection";
import { mainAPikey } from "../ContextApi";
import { CiCirclePlus } from "react-icons/ci";
import { RiEdit2Fill } from "react-icons/ri";
import { AiTwotoneDelete } from "react-icons/ai";

import {
  download_btn_icon,
  membercardlogo,
  memberlogo,
  insurer_dummy,
  tpa_dummy,
} from "../Assets/index";
import CashlessEditModal from "../components/CashlessEditModal";
import { RELATION_MASTER, REQUEST_MASTER } from "../Constants";
import CashlessDeleteModal from "../components/CashlessDeleteModal";
import { FaListUl } from "react-icons/fa6";
import RequestModal from "../components/RequestModal";

const Members_covered = () => {
  const [show, setShow] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [dataMsg, setdataMsg] = useState("");
  const [MembercoveredData, setMembercoveredData] = useState([]);
  const [editMember, setEditMember] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isErrorData, setisErrorData] = useState(false);
  const [EcardpdfData, setEcardpdfData] = useState();
  const [Errorfields, setErrorFields] = useState(null);
  const [insurerLogoPath, setInsurerLogoPath] = useState("");
  const [tpalogoPath, setTpalogoPath] = useState("");
  const [relations, setRelations] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [modaltypeData, setModalTypeData] = useState("cashless");
  const {
    featuresType,
    activeElementId,
    requestOptions,
    setRequestOptions,
    policytypeidcontext,
  } = useContext(AppContext);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const [appLinkToken, setappLinkToken] = useState(
    localStorage.getItem("appLinkToken")
  );
  const navigate = useNavigate();

  console.log(featuresType);
  async function handleTokenInResponse(response) {
    if (response.status === 401) {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(response.headers.get("Authorization"));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  const fetchRelations = async (addId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${mainAPikey}${RELATION_MASTER}?is_addition=${addId}`,
        requestOptions
      );
      const resData = await response.json();
      setRelations(resData?.data);
      console.log("API Response:", resData);
    } catch (error) {
      console.error("Error fetching relations:", error);
    }
  };

  const fetchRequest = async (addId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${mainAPikey}${REQUEST_MASTER}`,
        requestOptions
      );
      const resData = await response.json();
      setRequestData(resData?.data?.result);
      console.log("API Response:", resData);
    } catch (error) {
      console.error("Error fetching relations:", error);
    }
  };

  console.log(requestData);

  useEffect(() => {
    if (mainAPikey) {
      fetchRequest();
    }
  }, [mainAPikey]);

  useEffect(() => {
    if (activeElementId && token) {
      setisLoading(true);

      async function GetMemberCoveredData() {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL +
              `/${mainAPikey}` +
              `/employee/members-covered-list?policy_type=${policytypeidcontext}`,
            requestOptions
          );
          const tokenizedResponse = await handleTokenInResponse(response);
          const res = await tokenizedResponse.json();
          if (response.status === 429) {
            toast.error(
              "Oops! You have reached maximum limit, please try again after sometime."
            );
          }
          if (res.success) {
            setMembercoveredData(res.data.members);
            setEcardpdfData(res.data.ecard);
            setInsurerLogoPath(res.data.INSURER_LOGO_PATH);
            setTpalogoPath(res.data.TPA_LOGO_PATH);
            setisLoading(false);
          } else {
            setMembercoveredData(res.data);
            setdataMsg(res.message);
            setisLoading(false);
            setErrorFields(res.errors_fields);
            // console.log(res.errors_fields)
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setisLoading(false);
        }
      }

      GetMemberCoveredData();
    }
  }, [activeElementId, token]);

  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  const handleClose = () => setShow(false);
  const handleEditClose = () => setShowEdit(false);
  const handleDeleteClose = () => setShowDelete(false);

  console.log(mainAPikey);

  const openModal = (type, data) => {
    setModalTypeData(type);
    if (type === "cashless") {
      console.log();
    } else {
      fetchRelations("1");
    }
    setShow(true);
    setEditMember(data);
  };
  const rquestModal = () => {
    setShowRequest(true);
  };
  const openEditModal = (data) => {
    if (data?.correction_status === "approval_pending") {
      toast.error("Correction status is pending approval. Cannot edit.");
      return;
    }
    fetchRelations("0");
    setShowEdit(true);
    setEditMember(data);
  };

  const openDeleteModal = (data) => {
    if (data?.deletion_status === "approval_pending") {
      toast.error("Deletion status is pending approval. Cannot delete.");
      return;
    }
    setShowDelete(true);
    setEditMember(data);
  };

  const handleRequestClose = () => {
    setShowRequest(false);
  };

  return (
    <>
      <div className="member_main_outer_cover">
        <Header />
        <HeroSection />
        <div className="claims-tabs-section-cover-wrapper">
          <div className="row">
            <div className="col-lg-4">
              <div className="claims-header-tabs">
                <ClaimsFeatures />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="claims-content-cover-wrapper">
                {isLoading ? (
                  <CardLoader />
                ) : (
                  <div className="members-covered-main-cover-wrapper">
                    <div className="members-main-cover-wrapper">
                      <div className="members-download-card">
                        {EcardpdfData && EcardpdfData.length === 0 ? (
                          ""
                        ) : (
                          <button
                            class="Btn"
                            onClick={() => openModal("cashless")}
                          >
                            <div class="sign">
                              <img src={download_btn_icon} alt="Download" />
                            </div>
                            <div class="text">Download Cashless Card</div>
                          </button>
                        )}
                      </div>
                      <div className="members-covered-cards-wrapper mb-5">
                        {/* {featuresType !== "GPA" && featuresType !== "GTL" && (
                          <>
                            <button
                              className="add-members-btn"
                              onClick={() => openModal("add-members")}
                            >
                              <CiCirclePlus />
                              Add Dependent
                            </button>
                            {requestData?.length > 0 && (
                              <button
                                className="request-members-btn"
                                onClick={() => rquestModal()}
                              >
                                <FaListUl />
                                Request List
                              </button>
                            )}
                          </>
                        )} */}
                        <div className="row">
                          {MembercoveredData && MembercoveredData.length > 0 ? (
                            MembercoveredData.map((memberData, ind) => (
                              <>
                                <div className="col-lg-6" key={ind}>
                                  <div className="members-details-card">
                                    <div className="members-card-header d-flex justify-content-between align-items-center">
                                      <div className="member-card-header-details">
                                        <h4>{memberData?.member_name}</h4>
                                        <p className="p-0 m-0">
                                          {memberData.member_id}
                                        </p>
                                      </div>
                                      <div className="member-card-logo">
                                        {memberData.insurer_logo !== "NULL" &&
                                          memberData.insurer_logo !== null && (
                                            <img
                                              src={`${insurerLogoPath}${memberData.insurer_logo}`}
                                              alt="TPA Logo"
                                            />
                                          )}
                                      </div>
                                      {console.log(memberData)}
                                      {memberData?.member_relation !==
                                        "Employee" &&
                                        memberData?.member_relation !==
                                          "self" && (
                                          <div className="edit-delete-icons">
                                            <button
                                              onClick={() =>
                                                openEditModal(memberData)
                                              }
                                            >
                                              <RiEdit2Fill />
                                            </button>
                                            <button
                                              onClick={() =>
                                                openDeleteModal(memberData)
                                              }
                                            >
                                              <AiTwotoneDelete />
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                    <div className="member-card-details">
                                      <div className="members-card-content d-flex justify-content-between">
                                        <div className="members-date-text">
                                          <li>
                                            DOB :
                                            <span>
                                              {" "}
                                              {memberData.member_dob}
                                            </span>
                                          </li>
                                          <li>
                                            Relation:
                                            <span>
                                              {" "}
                                              {memberData.member_relation}
                                            </span>
                                          </li>
                                          <li>
                                            Age:{" "}
                                            <span>
                                              {" "}
                                              {memberData.member_age}
                                            </span>
                                          </li>
                                        </div>
                                        <div className="members-tpa-logo">
                                          {memberData.tpa_logo !== "" &&
                                            memberData.tpa_logo !== null && (
                                              <img
                                                src={`${tpalogoPath}${memberData.tpa_logo}`}
                                                alt="TPA Logo"
                                              />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            // <NoDataFound
                            //   msg={
                            //     Errorfields
                            //       ? Errorfields?.policy_type[0]
                            //       : dataMsg
                            //   }
                            // />
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CashlessModal
          data={EcardpdfData}
          show={show}
          handleClose={handleClose}
          type={modaltypeData}
          relationData={relations}
          apiCall={fetchRequest}
        />
        {/* <RequestModal
          data={requestData}
          show={showRequest}
          handleClose={handleRequestClose}
        />
        <CashlessEditModal
          data={editMember}
          show={showEdit}
          handleClose={handleEditClose}
          relationData={relations}
          apiCall={fetchRequest}
        />
        <CashlessDeleteModal
          data={editMember}
          show={showDelete}
          handleClose={handleDeleteClose}
          apiCall={fetchRequest}
        /> */}
        {/* <Wellness_Benefits appLinkToken={appLinkToken} /> */}
        <ToastContainer position="top-center" />
      </div>
    </>
  );
};

export default Members_covered;
