import PolicyTypeTab from "./PolicyTypeTab";
import { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../ContextApi";
import { wellnessNewDiagno , wellnessNewMed , wellnessNewtele , wellnessNewNutri , homeNavigationIcon } from "../Assets";
import TabLoader from "./TabLoader";
import { toast } from "react-toastify";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { APP_DIAGNOSTICS_URL, APP_MEDICINE_URL, APP_NUTRIOTION_URL, APP_TELECONSULT_URL } from "../Constants";

const HeaderTestTabs = () => {
  const {
    istabLoading, tabapiData
  } = useContext(AppContext);



  const [appLinkToken , setappLinkToken] = useState(localStorage.getItem("appLinkToken"))

  return (
    <>
      <div className="policy-type-tab-main-cover">
        <div className="insurance-product-title-cover mt-4">
          {/* Fade Up Effect for Title */}
          <h2 className="text-center" data-aos="fade-up">Unlock the  power of claims</h2>

          <div className="policy-type-tab-main-cover">

          {window.location.pathname != "/policy-details" && (
              <div className="policy-tab-box-cover" data-aos="fade-up" data-aos-delay="1500">
                <Link
                  to="/policy-details">
                  <div className="tab-box">
                    <div className="policytype-img-box">
                      <img src={homeNavigationIcon} alt="Teleconsultation" />
                    </div>
                  </div>
                </Link>
                <h5 className="m-0">Home</h5>
              </div>
            )
            }
            {istabLoading ? (
              <div className="tab_loadermain-wrapper">
                <TabLoader />
              </div>
            ) : (
              tabapiData.map((tabdata, ind) => {
                return (
                  <PolicyTypeTab
                    key={ind}
                    Tabicon={tabdata.logo}
                    fullName={tabdata.full_name}
                    policytypename={tabdata.policy_type_name}
                    policy_id={tabdata.policy_id}
                    policy_type_id={tabdata.policy_type_id}
                    intimateVisibility={tabdata.intimate_claim_visibility}
                    data-aos={ind % 2 === 0 ? "fade-up" : "fade-down"}
                    data-aos-delay={ind * 100}
                    isTabDisabled={tabdata.policy_type_id === 1 ||
                      tabdata.policy_type_id === 4 ||
                      tabdata.policy_type_id === 5}

                  />
                );
              })
            )}

            <div className="policy-tab-box-cover" >
              <Link to={
                appLinkToken
                  ? `${APP_MEDICINE_URL}?token=${appLinkToken}&href=${window.location.href}`
                  : "#"
              }
                onClick={(e) => {
                  if (!appLinkToken) {
                    e.preventDefault();
                    toast.error(
                      "App link token is missing. Cannot proceed."
                    );
                  }
                }} >
                <div className="tab-box">
                  <div className="policytype-img-box">
                    <img src={wellnessNewMed} alt="Medicines" />
                  </div>
                </div>
              </Link>
              <h5 className="m-0">Medicines</h5>
            </div>

            <div className="policy-tab-box-cover" >
              <Link to={`${APP_DIAGNOSTICS_URL}?token=${appLinkToken}&href=${window.location.href}`}
                onClick={(e) => {
                  if (!appLinkToken) {
                    e.preventDefault(); // Prevent navigation when appLinkToken is null
                    toast.error(
                      "App link token is missing. Cannot proceed."
                    );
                  }
                }}>
                <div className="tab-box">
                  <div className="policytype-img-box">
                    <img src={wellnessNewDiagno} alt="Diagnostics" />
                  </div>
                </div>
              </Link>
              <h5 className="m-0">Diagnostics</h5>
            </div>

            <div className="policy-tab-box-cover">
              <Link to={`${APP_NUTRIOTION_URL}?token=${appLinkToken}&dept_id=10&href=${window.location.href}`} >
                <div className="tab-box">
                  <div className="policytype-img-box">
                    <img src={wellnessNewNutri} alt="Nutrition" />
                  </div>
                </div>
              </Link>
              <h5 className="m-0">Nutrition</h5>
            </div>

            <div className="policy-tab-box-cover" >
              <Link to={`${APP_TELECONSULT_URL}?token=${appLinkToken}&href=${window.location.href}`}
                onClick={(e) => {
                  if (!appLinkToken) {
                    e.preventDefault(); // Prevent navigation when appLinkToken is null
                    toast.error(
                      "App link token is missing. Cannot proceed."
                    );
                  }
                }}>
                <div className="tab-box">
                  <div className="policytype-img-box">
                    <img src={wellnessNewtele} alt="Teleconsultation" />
                  </div>
                </div>
              </Link>
              <h5 className="m-0">Teleconsultation</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTestTabs;
