import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useContext, useEffect, useState } from "react";
import { pdficon, download_pdf_icon, downloadbannerimg } from "../Assets/index";
import { AppContext, mainAPikey } from "../ContextApi";
import { useForm, Controller } from "react-hook-form";
import { validateNumberInput, validateTextInput } from "../Utils";
import { ADD_EMPLOYEE_API } from "../Constants";
import { toast } from "react-toastify";

function CashlessModal(props) {
  const { data, type, relationData } = props;
  const { constantsApiData } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);

  const handleDownload = (url) => {
    // console.log(E_CARDS_DATA_PATH)
    const EcardsUrl = constantsApiData.path.E_CARDS_DATA_PATH;
    if (url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open(`${EcardsUrl}${url}`, "_blank");
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm({});

  const authorizationToken = localStorage.getItem("authorizationToken");

  const DOB = watch("DOB"); // Watch for changes in the DOB field
  const today = new Date().toISOString().split("T")[0];

  // Function to calculate age based on the DOB
  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    // If the birth month hasn't occurred yet in the current year, subtract 1 from the age
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    if (DOB) {
      const calculatedAge = calculateAge(DOB);
      setValue("age", calculatedAge); // Automatically update the age field
    }
  }, [DOB, setValue]);

  const onSubmit = async (data) => {
    setLoading(true); // Show loading state during submission

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    const apiUrl = `${process.env.REACT_APP_API_URL}/${mainAPikey}${ADD_EMPLOYEE_API}`;

    const requestOptionsNew = {
      method: "POST",
      headers: {
        Authorization: authorizationToken,
      },
      body: formData,
    };

    try {
      const response = await fetch(apiUrl, requestOptionsNew);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json(); // Convert response to JSON

      console.log("Response:", result);

      if (result?.success === true) {
        toast.success(result.message || "Form submitted successfully!"); // Show toast message
        props.apiCall();
        props.handleClose(); // Close the modal
        reset(); // Reset the form after submission
      } else {
        toast.error(result.message || "Something went wrong!"); // Show error message
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit the form. Please try again."); // Show error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const selectedRelationId = watch("relationship_master_id");

  const selectedRelation = relationData?.find(
    (relation) => relation.id.toString() === selectedRelationId
  )?.relationship;

  const showDOM = ["Spouse", "Husband", "Wife"].includes(selectedRelation);

  return (
    <Modal {...props} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        {type == "cashless" ? (
          <Modal.Title>Download documents {props.keyvalue}</Modal.Title>
        ) : (
          <Modal.Title>Add Members</Modal.Title>
        )}
        <Button onClick={props.handleClose}>x</Button>
      </Modal.Header>
      <Modal.Body>
        <div className="pdf-download-cover-wrapper">
          {type == "cashless" ? (
            <div className="row">
              {data?.map((elem) => {
                return (
                  <div className="col-lg-4">
                    <div className="download-pdf-box">
                      <div className="pdf-img-box">
                        <img src={pdficon}></img>
                      </div>
                      <div className="pdf-name-box">
                        <h5>{elem.original_file_name}</h5>
                      </div>

                      <div
                        className="pdf-download-icon-box"
                        onClick={() => handleDownload(elem.file_name)}
                      >
                        <img src={download_pdf_icon}></img>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="add-form-members-main-cover-wrapper">
              <form
                id="form-step-1"
                className="form-step active"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row claim-intimate-forms-cover d-flex">
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label>
                        Name <span className="error"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        {...register("name", { required: "Name is required" })}
                        defaultValue=""
                      />
                      {errors.name && (
                        <small className="text-danger">
                          {errors.name.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>
                        Gender <span className="error"> * </span>
                      </label>
                      <select
                        className="form-control"
                        {...register("gender", {
                          required: "Gender is required",
                        })}
                        defaultValue=""
                      >
                        <option value="">Select gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                    {errors.gender && (
                      <small className="text-danger">
                        {errors.gender.message}
                      </small>
                    )}
                  </div>

                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label>
                        Date of Birth <span className="error"> * </span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("DOB", { required: "DOB is required" })}
                        defaultValue=""
                        max={today}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>
                        Age <span className="error"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Age"
                        {...register("age", { required: "Age is required" })}
                        defaultValue=""
                        readOnly // Make age read-only as it will be auto-filled
                      />
                    </div>
                    {errors.age && (
                      <small className="text-danger">
                        {errors.age.message}
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>
                        Select Relation <span className="error"> * </span>
                      </label>
                      <select
                        className="form-control"
                        {...register("relationship_master_id", {
                          required: "Relation is required",
                        })}
                      >
                        <option value="">Select a Relation</option>
                        {/* Assuming 'relations' is the list of relations you want to show */}
                        {relationData?.map((relation) => (
                          <option key={relation.id} value={relation.id}>
                            {relation.relationship}
                          </option>
                        ))}
                      </select>
                      {errors.relationship_master_id && (
                        <small className="text-danger">
                          {errors.relationship_master_id.message}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="form-group">
                      <label>
                        Remark <span className="error"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Remark"
                        {...register("remarks", {
                          required: "Remark is required",
                        })}
                        defaultValue=""
                      />
                      {errors.remarks && (
                        <small className="text-danger">
                          {errors.remarks.message}
                        </small>
                      )}
                    </div>
                  </div>
                  {showDOM && (
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>
                          Date of Marriage <span className="error"> * </span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("date_of_marriage", {
                            required: "Date Of Marriage is required",
                          })}
                          defaultValue=""
                          max={today}
                        />
                      </div>
                    </div>
                  )}
                  {/* 
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>
                        Mobile Number <span className="error"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("mobile_number", {
                          required: "Mobile number is required",
                        })}
                        placeholder="Enter mobile number"
                        defaultValue=""
                      />
                    </div>
                    {errors.mobile_number && (
                      <small className="text-danger">
                        {errors.mobile_number.message}
                      </small>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>
                        Email ID <span className="error"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("email_id", {
                          required: "Email ID is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address",
                          },
                        })}
                        placeholder="Enter email id"
                        defaultValue=""
                      />
                      {errors.email_id && (
                        <small className="text-danger">
                          {errors.email_id.message}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>
                        Date of Joining <span className="error"> * </span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("date_of_joining", {
                          required: "Date of joining is required",
                        })}
                        defaultValue=""
                      />
                    </div>
                    {errors.date_of_joining && (
                      <small className="text-danger">
                        {errors.date_of_joining.message}
                      </small>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label>
                        Designation <span className="error"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("designation", {
                          required: "Designation is required",
                        })}
                        placeholder="Enter designation"
                        defaultValue=""
                      />
                    </div>
                    {errors.designation && (
                      <small className="text-danger">
                        {errors.designation.message}
                      </small>
                    )}
                  </div> */}
                </div>
                <div className="repo-disc-loki">
                  <p
                    style={{
                      fontSize: "13px",
                      color: "red",
                      marginBottom: "0px",
                    }}
                  >
                    Note: Only newborn baby and newlywed spouse are allowed.
                    Mid-term additions are not permitted.
                  </p>
                </div>
                <div className="submit-btn-box">
                  <input
                    type="submit"
                    className="register-form-btn mt-4 mb-3"
                    value={isLoading ? "please wait..." : "Submit"}
                    disabled={isLoading}
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CashlessModal;
