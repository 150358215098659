import {
  productBike,
  productCar,
  productHealthFloater,
  productHealthIndividual,
} from "../Assets";
import { useContext } from "react";
import { AppContext } from "../ContextApi";
import { Link } from "react-router-dom";
import { EMED_WEB_LINK } from "../Constants";
import { toast, ToastContainer } from "react-toastify";

const InsuranceProducts = () => {
  const { employeeUserData } = useContext(AppContext);

  function base64Encode(data) {
    return btoa(JSON.stringify(data)); // Encode JSON string in base64
  }

  const handleUrl = (e, journey_type, request_type) => {
    e.preventDefault();

    if(EMED_WEB_LINK){
      const encryptedData = base64Encode({
        name: `${employeeUserData?.first_name} ${employeeUserData?.last_name}`,
        email: employeeUserData?.email,
        mobile: employeeUserData?.mobile_no,
        redirect_url : window.location.href,
        journey_type,
        request_type
      });
    
      const url = `${EMED_WEB_LINK}/auto-login?data=${encodeURIComponent(encryptedData)}`;
      window.open(url, '_blank');
    }else{
      toast.success("Coming Soon")
    }
  };
  
  return (
    <>
      <div className="insurance-product-main-cover-wrapper">
          <div className="insurance-product-cover-box">
            <div className="insurance-content-cover">
              <div className="row d-flex align-items-center">
              <div className="insurance-product-title-cover">
                <h4 className="">Insurance Products</h4>
              </div>
                <div className="col-lg-5">
                  <div className="insurance-product-box">
                    <h5>
                      Car <span className="">Insurance</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e , 1 , 1)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productCar} alt="car" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="insurance-product-box">
                    <h5>
                      Bike <span className="">Insurance</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e , 1 , 2)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productBike} alt="bike Insurance" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="insurance-product-box">
                    <h5>
                      Health <span className="">Individual</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e,2, 1)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productHealthIndividual} alt="Health Insurance" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="insurance-product-box">
                    <h5>
                      Health <span className="">Floater</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e , 2 , 2)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productHealthFloater} alt="Health Insurance" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <ToastContainer position="top-center" />
    </>
  );
};

export default InsuranceProducts;
